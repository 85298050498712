<template>
    <PageLoader>
        <section class="mt-10">
            <Header :title="$tc('client_detail', 2)" heading="h3" />

            <div class="mt-5 grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-span-6">
                    <p class="text-sm text-gray-500">{{ $tc('name', 1) }}</p>
                    <p>
                        {{ clientInfo.name }}
                    </p>
                </div>

                <div class="col-span-6">
                    <p class="text-sm text-gray-500">{{ $tc('enterprise_number', 1) }}</p>
                    <p>
                        {{ clientInfo.enterprise_number }}
                    </p>
                </div>

                <div class="col-span-6">
                    <p class="text-sm text-gray-500">{{ $tc('email', 1) }}</p>
                    <p>
                        {{ clientInfo.email }}
                    </p>
                </div>

                <div class="col-span-6">
                    <p class="text-sm text-gray-500">{{ $tc('phone', 1) }}</p>
                    <p>
                        {{ clientInfo.phone }}
                    </p>
                </div>

                <div class="col-span-6">
                    <p class="text-sm text-gray-500">{{ $tc('address', 1) }}</p>
                    <p>
                        {{ clientInfo.street }} {{ clientInfo.number }} {{ clientInfo.addition }}<br />
                        {{ clientInfo.zip }} {{ clientInfo.city }}
                    </p>
                </div>
                <div class="col-span-6">
                    <p class="text-sm text-gray-500">{{ $tc('nace_code', 2) }}</p>
                    <p>
                        TO DO: VAT Nace<br />
                        TO DO: NSSO Nace
                    </p>
                </div>
            </div>
        </section>

        <section class="mt-10">
            <Header :title="$tc('contact', 2)" heading="h3" />

            <table class="mt-5 min-w-full divide-y divide-fibonaki-border">
                <thead>
                    <tr>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('first_name', 1) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('last_name', 1) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('date_of_birth', 1) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('phone', 1) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('email', 1) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="contact in clientContacts" :key="contact">
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ contact.first_name }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ contact.last_name }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanDate(contact.date_of_birth) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ contact.phone }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ contact.email }}</td>
                    </tr>
                </tbody>
            </table>
        </section>
    </PageLoader>
</template>

<script>
    export default {
        data() {
            return {};
        },
        computed: {
            clientInfo() {
                return this.$store.getters['clients/profileDetails'];
            },
            clientContacts() {
                return this.$store.getters['clients/contacts'];
            },
        },
    };
</script>
